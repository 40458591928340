import httpUtil from "@/utils/httpUtil";

//获取设备列表
export const getDeviceList = params => httpUtil.get("/gpsDevice", params);
//不分页设备列表
export const getNoPage = params => httpUtil.get("/gpsDevice/noPage", params);

export const getDeviceInfo = params => httpUtil.get("/gpsDevice/getDeviceInfo", params);

//新增或修改设备
export const add = params => httpUtil.post("/gpsDevice", params);

export const delDevice = params => httpUtil.post("/gpsDevice/delete", params);