<template>
  <div>
    <div class="searchMain">
      <el-select v-model="deviceId" placeholder="请选择" @change="chooseDevice">
        <el-option
            v-for="item in deviceList"
            :key="item.id"
            :label="item.deviceName"
            :value="item.id">
        </el-option>
      </el-select>
      <el-date-picker
          v-model="timeRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
      >
      </el-date-picker>
      <el-select v-model="type" placeholder="请选择类型">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <el-button type="primary" @click="search">搜索</el-button>
    </div>
    <div id="container" class="gpsMapContainer"></div>
    <div class="deviceInfo">
      <div class="input-item">
        <div class="infoDiv">
          <div class="deviceInfoItem">
            <el-image
                style="width: 40px; height: 40px"
                :src="baseImgUrl+'imei.png'"
                fit="fit"></el-image>
          </div>
          <div class="deviceInfoData">
            <span class="deviceInfoText">IMEI号</span>
            <span class="info_data">{{ deviceInfo.iccid }}</span>
          </div>
        </div>
        <div class="infoDiv">
          <div class="deviceInfoItem">
            <el-image
                style="width: 40px; height: 40px"
                :src="baseImgUrl+'dt.png'"
                fit="fit"></el-image>
          </div>
          <div class="deviceInfoData">
            <span class="deviceInfoText">设备电压</span>
            <span class="info_data">{{ deviceInfo.vbat / 1000 || '--'}}V</span>
          </div>
        </div>
        <div class="infoDiv">
          <div class="deviceInfoItem">
            <el-image
                style="width: 40px; height: 40px"
                :src="baseImgUrl+'wd.png'"
                fit="fit"></el-image>
          </div>
          <div class="deviceInfoData">
            <span class="deviceInfoText">设备温度</span>
            <span class="info_data">{{ deviceInfo.temp || '--'}}℃</span>
          </div>
        </div>
        <div class="infoDiv">
          <div class="deviceInfoItem">
            <el-image
                style="width: 40px; height: 40px"
                :src="baseImgUrl+'dq.png'"
                fit="fit"></el-image>
          </div>
          <div class="deviceInfoData">
            <span class="deviceInfoText">指定区域</span>
            <span class="info_data" v-for="item in deviceInfo.deviceAreas" :key="item.id">{{ item.address || '--'}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="input-card">
      <h4><span class="line-left"></span>轨迹回放控制</h4>
      <div class="input-item btnList">
        <el-button class="startBtn" id="start" @click="startAnimation" icon="el-icon-edit" >开始动画</el-button>
        <el-button class="ztBtn"  id="pause" @click="pauseAnimation" icon="el-icon-eleme" >暂停动画</el-button>

<!--        <input type="button"  class="btn" value="开始动画"  icon="el-icon-edit" id="start" @click="startAnimation"/>-->
<!--        <input type="button" class="btn" value="暂停动画" id="pause" @click="pauseAnimation"/>-->
      </div>
      <div class="input-item btnList">
        <el-button class="jxBtn" id="resume" @click="resumeAnimation" icon="el-icon-s-tools" >继续动画</el-button>
        <el-button class="tzBtn"  id="stop" @click="stopAnimation" icon="el-icon-setting" >停止动画</el-button>
<!--        <input type="button" class="btn" value="继续动画" id="resume" @click="resumeAnimation"/>-->
<!--        <input type="button" class="btn" value="停止动画" id="stop" @click="stopAnimation"/>-->
      </div>
    </div>

  </div>
</template>
<script>

import out from "../warehouseLog/out.vue";

window._AMapSecurityConfig = {
  securityJsCode: "c100a1dec521518acc0fa32dee77d9d0",
};
import AMapLoader from "@amap/amap-jsapi-loader"
import {fetchGpsData,} from '@/api/warehouseManage.js'
import {getNoPage, getDeviceInfo} from '@/api/gps.js'
import {
  baseImgUrl
} from '@/utils/constant'

export default ({
  data() {
    return {
      baseImgUrl: baseImgUrl,
      timeRange: '',
      type: 0,
      options: [{
        label: '基站',
        value: 0,
      }, {
        label: 'gps',
        value: 1,
      },],
      map: null, //初始化 map 对象
      markers: [], // 存储标记
      polyline: null, //存储路线
      trackPoints: [],
      points: {},
      cluster: null,
      deviceId: '',
      deviceList: [],
      deviceInfo: {},
      marker: null, // 车marker
      lineArr: [],
      markerPolyline: null,
      obj:{},
    }
  },
  mounted() {
    this.getDeviceList()
  },
  methods: {
    chooseDevice(){
      this.getDevice()
      this.$nextTick(function () {
        this.initMapWithData();
      })
    },
    getDeviceList() {
      getNoPage().then(res => {
        this.deviceList = res.data
        if (!this.deviceId && this.deviceList.length > 0) {
          this.deviceId = this.deviceList[0].id
          this.getDevice()
          this.$nextTick(function () {
            this.initMapWithData();
          })
        }
      })
    },
    getDevice() {
      getDeviceInfo({deviceId: this.deviceId}).then(res => {
        this.deviceInfo = res.data
      })
    },
    formatDate(date, format) {
      const map = {
        'MM': date.getMonth() + 1,
        'dd': date.getDate(),
        'yyyy': date.getFullYear(),
        'HH': date.getHours(),
        'mm': date.getMinutes(),
        'ss': date.getSeconds(),
      };

      return format.replace(/MM|dd|yyyy|HH|mm|ss/g, match => {
        return map[match].toString().padStart(2, '0');
      });
    },
    initMapWithData() {
      const MAP_PLUGINS = [
        "AMap.Scale",
        "AMap.ToolBar",
        "AMap.ControlBar",
        "AMap.HawkEye",
        "AMap.Circle",
        "AMap.Polygon",
        "AMap.Polyline",
        "AMap.Marker",
        "AMap.Pixel",
        "AMap.InfoWindow",
        "AMap.Icon",
        "AMap.MarkerCluster",
        "AMap.MoveAnimation",
        "AMap.GraspRoad",
        "AMap.DistrictSearch",
      ];
      AMapLoader.reset()
      AMapLoader.load({
        key: "872463d5f9926f3560506443698de6f0",
        version: "2.0",
        plugins: MAP_PLUGINS,
      }).then((AMap) => {
        const map = new AMap.Map("container", {
          viewMode: "3D",
          terrain: true,
          zoom: 11,
          center: [116.41, 39.91],
        });
        this.map = map;
        this.init()
        this.trajectory(); //轨迹
      });
    },
    // 绘制轨迹
    trajectory(type, trackPoints) {
      this.addStartPointMarker(type, trackPoints); //开始的点
      this.addPolyline(type, trackPoints); //轨迹的线
      this.addEndPointMarker(type, trackPoints); //结束的点
      // this.map.setFitView();
    },
    // 点聚合
    renderClusterMarker(type, points) {
      //聚合点样式
      var that = this
      var _renderClusterMarker = function (context) {
        //context 为回调参数，
        //包含如下属性 marker:当前聚合点，count:当前聚合点内的点数量
        var clusterCount = context.clusterData.length // context.count; //聚合点内点数量
        context.marker.setContent(
            // `<div style="background: ${ type == 0 ? "#0091ff" :'#0f0'}"; border-radius: 10px; width: 20px; text-align: center; color: #fff>` + clusterCount + "</div>"
            `<div style="background: ${type == 0 ? "#0091ff" : '#67c23a'}; border-radius: 10px; width: 20px; text-align: center; color: #fff">${clusterCount}</div>`
        );
        context.marker.on("click", function (e) {
          that.clickEvent(e, context, type)
        });
      };
      //非聚合点样式
      var _renderMarker = function (context) {
        //context 为回调参数，
        //包含如下属性 marker:当前非聚合点
        context.marker.setContent(`<div style="background: ${type == 0 ? "#0091ff" : '#0f0'}; border-radius: 10px; width: 20px; text-align: center; color: #fff">1</div>`);
        context.marker.on("click", function (e) {
          that.clickEvent(e, context, type)

        });
      };

      var map = this.map
      map.plugin(["AMap.MarkerCluster"], function () {
        cluster = new AMap.MarkerCluster(
            map, //地图实例
            points, //海量点数据，数据中需包含经纬度信息字段 lnglat
            {
              gridSize: 60, //数据聚合计算时网格的像素大小
              renderClusterMarker: _renderClusterMarker, //上述步骤的自定义聚合点样式
              renderMarker: _renderMarker, //上述步骤的自定义非聚合点样式
            }
        );
        that.cluster = cluster
      });

    },
    // 点击点聚合点
    clickEvent(e, context, type) {
      // 自定义弹窗内容
      console.log('context', context)
      let dataArr = context.clusterData ? context.clusterData : context.data
      let str = ''
      for (let i = 0; i < dataArr.length; i++) {
        str += `<div class="dataItem"><div><span>时间：</span><span>${dataArr[i].createTime}</span></div></div>`
        // str+=`<div class="dataItem"><div><span>时间：</span><span>${dataArr[i].createTime}</span></div><div><span>位置：</span><span>[${dataArr[i].lnglat.lng},${dataArr[i].lnglat.lat}]</span></div></div>`
      }
      const infoWindowContent = `
                    <div>
                        <div style="font-size: 14px;text-align: center;background-color: #00aaa3;color: #fff;padding: 10px 0">
                            <span>${type == 0 ? '基站' : 'gps'}信息</span>
                        </div>
                        <div style="padding: 10px;font-size: 12px;background-color: #fff">
                          ${str}
                        </div>
                    </div>
                    `;
      const infoWindow = new AMap.InfoWindow({
        content: infoWindowContent,
        // isCustom: true, //自定义窗体，设置为false为默认弹窗
        offset: new AMap.Pixel(5, 0), // 设置弹窗的位置
      });
      infoWindow.open(this.map, e.target.getPosition());
    },
    // 控制轨迹动画
    startAnimation() {
      // this.marker.moveAlong(this.trackPoints,500)
      this.marker.moveAlong(this.lineArr, {
        // 每一段的时长
        duration: 500,//可根据实际采集时间间隔设置
        // JSAPI2.0 是否延道路自动设置角度在 moveAlong 里设置
        autoRotation: true,
      })
      // this.marker.moveAlong(this.trackPoints, {
      //     // 每一段的时长
      //     duration: 5000*1000,//可根据实际采集时间间隔设置
      //     // JSAPI2.0 是否延道路自动设置角度在 moveAlong 里设置
      //     autoRotation: true,
      // });
    },

    pauseAnimation() {
      this.marker.pauseMove();
    },

    resumeAnimation() {
      this.marker.resumeMove();
    },

    stopAnimation() {
      this.marker.stopMove();
    },
    // 轨迹动画
    trajectoryMove(type, trackPoints) {
      var that = this
      that.trackPoints = trackPoints
      // if(type == 0) {
      //标记车辆
      let marker = new AMap.Marker({
        position: trackPoints[0],
        icon: "https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png",
        //坐标偏移
        offset: new AMap.Pixel(-13, -26),
        autoRotation: true,
        angle: 60,
        map: that.map
      });
      // 经过的线
      var passedPolyline = new AMap.Polyline({
        map: that.map,
        strokeColor: "#999999",  //线颜色 #AF5
        strokeWeight: 6,      //线宽
        showDir: true,
        strokeOpacity: 0.9
      });
      marker.on("moving", function (e) {
        //passedPath为Marker对象在moveAlong或者moveTo过程中已经走过的路径
        //通过passedPath 给passedPolyline 设置path 也就是让他开始画绿色的线
        // that.map.setFitView();
        // console.log('e',e)
        passedPolyline.setPath(e.passedPath);
        // passedPolyline.setStrokeColor('#f00')
        that.map.setCenter(e.target.getPosition(), true)
        // that.map.setFitView()
      });
      that.marker = marker

      // debugger
      // that.marker.moveAlong(trackPoints,200);

      // marker0.moveAlong(trackPoints,1000 * 300);
      // }else {
      // setTimeout(function(){
      //     let marker1 = new AMap.Marker({
      //         position:trackPoints[0],
      //         icon: "https://webapi.amap.com/images/car.png",
      //         //坐标偏移
      //         offset: new AMap.Pixel(-26, -13),
      //         autoRotation: true,
      //         angle:-90,
      //         map:that.map
      //     });
      //     that.map.setFitView();
      //     marker1.moveAlong(trackPoints,1000 * 300);
      // }, 1000 * 300)
      //标记车辆
      // }

    },
    addStartPointMarker(type, trackPoints) {
      const icon = new AMap.Icon({
        size: new AMap.Size(20, 30), // 设置图标的宽度和高度
        imageSize: new AMap.Size(20, 30), // 设置图标显示时的大小
        image: "https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png", // 自定义图标的 URL
      });
      const startPoint = new AMap.Marker({
        position: trackPoints[0],
        map: this.map,
        icon: icon,
        offset: new AMap.Pixel(-10, -30),
      });
      startPoint.setLabel({
        offset: new AMap.Pixel(0, 0),  //设置文本标注偏移量
//                 content: "<div style='position: relative;'>"+'起点'+"</div>", //设置文本标注内容
        content: `<div style='position: relative;'>${type == 0 ? '基站' : 'gps'}起点</div>`,
        direction: 'bottom' //设置文本标注方位
      });
      this.markers.push(startPoint);
    },
    addEndPointMarker(type, trackPoints) {
      const lastPoint = trackPoints[trackPoints.length - 1];
      const icon = new AMap.Icon({
        size: new AMap.Size(20, 30), // 设置图标的宽度和高度
        imageSize: new AMap.Size(20, 30), // 设置图标显示时的大小
        image: "https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png", // 自定义图标的 URL
      });
      const endPoint = new AMap.Marker({
        position: lastPoint,
        map: this.map,
        icon: icon,
        offset: new AMap.Pixel(-10, -30),
      });
      endPoint.setLabel({
        offset: new AMap.Pixel(0, 0),  //设置文本标注偏移量
        content: `<div style='position: relative;'>${type == 0 ? '基站' : 'gps'}终点</div>`, //设置文本标注内容
        direction: 'bottom' //设置文本标注方位
      });
      this.markers.push(endPoint);
    },
    addPolyline(type, trackPoints) {
      // 绘制箭头
      // var canvasDir = document.createElement('canvas')
      // var width = 24;
      // canvasDir.width = width;
      // canvasDir.height = width;
      // var context = canvasDir.getContext('2d');
      // context.strokeStyle = 'white';
      // context.lineJoin = 'round';
      // context.lineWidth = 8;
      // context.moveTo(-4, width - 4);
      // context.lineTo(width / 2, 6);
      // context.lineTo(width + 4, width - 4);
      // var polyline = new AMap.Polyline({
      //   path: this.points.outArr,
      //   isOutline: false,
      //   outlineColor: type == 0 ? "#ffeeee" : '#67c23a',
      //   borderWeight: 2,
      //   strokeColor: "#d92828",
      //   strokeOpacity: 0.9,
      //   // dirImg: canvasDir,
      //   strokeWeight: 6,
      //   lineJoin: "round",
      //   showDir: true,
      //
      // });
      // var polyline2 = new AMap.Polyline({
      //   path: this.points.inArr,
      //   isOutline: false,
      //   outlineColor: type == 0 ? "#ffeeee" : '#67c23a',
      //   borderWeight: 2,
      //   strokeColor: type == 0 ? "#0091ff" : '#67c23a',
      //   strokeOpacity: 0.9,
      //   // dirImg: canvasDir,
      //   strokeWeight: 6,
      //   lineJoin: "round",
      //   showDir: true,
      // });
      // this.map.add([polyline, polyline2]);
      let obj = this.obj;
      let objKeyArr = Object.keys(obj);
      // 区域内、区域外首尾坐标连接 防止出现断续情况
      for (let i = 0; i < objKeyArr.length; i++) {
        if (i < objKeyArr.length - 1) {
          obj[objKeyArr[i]].push(obj[objKeyArr[i + 1]][0]);
        }
      }
      // 设置地图的中心点
      let centerLnglat = obj[objKeyArr[0]][0];
      console.log(centerLnglat); //[113.897352, 22.471799]
      this.map.setZoomAndCenter(14, centerLnglat, true);
      // 绘画轨迹
      let lineArr = [];
      for (let i in obj) {
        let color = ""; // 定义轨迹的颜色变量
        let type = i.split("_"); // 获取轨迹是在区域内还是区域外
        if (type[0] == "in") {
          color = "#0abc4d";
        } else if (type[0] == "out") {
          color = "#d92828";
        }
        // 配置轨迹
        name = new AMap.Polyline({
          map: this.map,
          path: obj[i], // 轨迹的坐标数组
          showDir: true,
          strokeColor: color,
          strokeWeight: 6, //线宽
          lineJoin: "round",
          strokeOpacity: 0.9
        });
        lineArr = lineArr.concat(obj[i]);
      }
      this.lineArr = lineArr
    },
    init() {
      const now = new Date();
      const end = this.formatDate(now, 'yyyy-MM-dd');
      const startDate = new Date(now.getTime() - 24 * 60 * 60 * 1000)
      const start = this.formatDate(startDate, 'yyyy-MM-dd');
      this.timeRange = [start, end]
      // 基站直接画线
      this.getData(0)
      // gps纠偏画线
      // this.getData(1)
      // 绘制区域
      for (let i = 0; i < this.deviceInfo.deviceAreas.length; i++) {
        let item = this.deviceInfo.deviceAreas[i]
        var level = item.county ? 'district' : (item.city ? 'city' : (item.province ? 'province' : 'city'))
        var code = item.county ? item.county : (item.city ? item.city : (item.province ? item.province : item.address))
        this.drawBounds(code, level)
      }
    },
    // 绘制当前区域 name: 北京市
    drawBounds(code, level) {
      const district = new AMap.DistrictSearch({
        subdistrict: 0, //获取边界不需要返回下级行政区
        extensions: "all", //返回行政区边界坐标组等具体信息
        level: level //"district" //查询行政级别为县区
      });
      var that = this
      district.search(String(code), (state, res) => {
        var polygons = []
        res.districtList[0].boundaries.forEach(q => {
          const polygon = new AMap.Polygon({
            strokeWeight: 1,
            path: q,
            fillOpacity: 0.4,
            // fillColor: "#143048",
            // strokeColor: "#0A1A29"
            fillColor: '#80d8ff',
            strokeColor: '#0091ea'
          });
          polygons.push(polygon);
        });
        that.map.add(polygons);
        that.map.setFitView(that.polygons); // 自适应缩放比
      });
    },
    //
    getData(type) {
      let params = {
        startTime: this.timeRange ? this.timeRange[0] : '',
        endTime: this.timeRange ? this.timeRange[1] : '',
        type: type,
        deviceId: this.deviceId
      }
      fetchGpsData(params).then(res => {
        if (res.code === 200) {
          // if(params.type === 0) {
          let arr = []
          let points = []
          // 数据处理  将区域内 区域外数据进行连贯拆分，5分钟内的同一坐标进行剔除
          let filterArr = []
          let obj = {}
          let inNum = 0;
          let outNum = 0;
          for (let i = 0; i < res.data.length; i++) {
            let item = res.data[i];
            let index = points.findLastIndex(a => a.lat == item.lat && a.lon == item.lon);
            let filterIndex = filterArr.findLastIndex(a => a.lat == item.lat && a.lon == item.lon);
            if (index > 0) {
              if (filterIndex > 0) {
                let filterArrElement = filterArr[filterIndex];
                let time = this.differMinuteTime(filterArrElement.createTime, item.createTime);
                if (time <= 5) {
                  continue;
                }
              }
              let point = points[index];
              let number = this.differMinuteTime(point.createTime, item.createTime);
              if (number <= 5) {
                filterArr.push({
                  lat: item.lat,
                  lon: item.lon,
                  createTime: item.createTime,
                  lnglat: [item.lon, item.lat],
                  inInside: item.inInside
                })
                continue;
              }
            }
            if (item.inInside) {
              if (inNum == 0||inNum <=outNum){
                ++inNum;
              }
              let key = "in_"+inNum;
              let in1 = obj[key];
              if (in1){
                obj[key].push([
                  JSON.parse(item.lon),
                  JSON.parse(item.lat)
                ])
              }else {
                obj[key] = [[
                  JSON.parse(item.lon),
                  JSON.parse(item.lat)
                ]]
              }
              // inArr.push([
              //   JSON.parse(item.lon),
              //   JSON.parse(item.lat)
              // ])
            } else {
              if ( outNum == 0 || outNum<= inNum){
                ++outNum;
              }
              let key = "out_"+outNum;
              let out = obj[key];
              if (out){
                obj[key].push([
                  JSON.parse(item.lon),
                  JSON.parse(item.lat)
                ])
              }else {
                obj[key] = [[
                  JSON.parse(item.lon),
                  JSON.parse(item.lat)
                ]]
              }
              // outArr.push([
              //   JSON.parse(item.lon),
              //   JSON.parse(item.lat)
              // ])
            }
            points.push({
              lat: item.lat,
              lon: item.lon,
              createTime: item.createTime,
              lnglat: [item.lon, item.lat],
              inInside: item.inInside
            })
            arr.push([
              JSON.parse(item.lon),
              JSON.parse(item.lat)
            ])
          }
          // res.data.forEach(item=>{
          //
          // })
          // this.points = {
          //   inArr: inArr,
          //   outArr: outArr
          // }
          this.obj = obj
          // 绘制轨迹
          this.trajectory(params.type, arr)
          // }
          // else {
          //     // 绘制纠偏后的轨迹
          //         var graspRoad;
          //         if(!graspRoad) {
          //             graspRoad = new AMap.GraspRoad()
          //         }
          //         var that = this
          //         var pathParam= []
          //         let points = []
          //         pathParam = res.data.map(item => {
          //             points.push({
          //                 createTime: item.createTime,
          //                 lnglat: [item.lon, item.lat] ,
          //                 inInside: item.inInside
          //             })
          //             return {
          //              "x": item.lon,
          //              "y": item.lat,
          //              "sp": item.sp,
          //              "ag": item.ag,
          //              "tm": item.tm,
          //             }
          //         })
          //         // console.log('pathParam', pathParam)
          //         // var pathParam= [
          //         //     {"x":116.478928,"y":39.997761,"sp":19,"ag":0, "tm":1478031031},
          //         //     {"x":116.478907,"y":39.998422,"sp":10,"ag":0, "tm":2},
          //         //     {"x":116.479384,"y":39.998546,"sp":10,"ag":110,"tm":3},
          //         //     {"x":116.481053,"y":39.998204,"sp":10,"ag":120,"tm":4},
          //         //     {"x":116.481793,"y":39.997868,"sp":10,"ag":120,"tm":5},
          //         //     {"x":116.482898,"y":39.998217,"sp":10,"ag":30, "tm":6},
          //         //     {"x":116.483789,"y":39.999063,"sp":10,"ag":30, "tm":7},
          //         //     {"x":116.484674,"y":39.999844,"sp":10,"ag":30, "tm":8}]
          //         graspRoad.driving(pathParam,function(error,result){
          //             if(!error){
          //                     var path2 = [];
          //                     var newPath = result.data.points;
          //                     for(var i =0;i<newPath.length;i+=1){
          //                         path2.push([newPath[i].x,newPath[i].y])
          //                     }
          //                     console.log('path2', path2)
          //                     that.trajectory(params.type,path2)
          //                 }
          //         })
          // }
          // console.log('pints', points)
          // 绘制轨迹
          // this.trajectory(params.type,arr)

          // 轨迹回放动画
          this.trajectoryMove(params.type, arr)
          // var points = [
          //     {  lnglat: ["116.506647", "39.795337"] },
          //     {  lnglat: ["116.843352", "40.377362"] },
          //     {  lnglat: ["116.637122", "40.324272"] },
          //     {  lnglat: ["116.105381", "39.937183"] },
          //     {  lnglat: ["116.653525", "40.128936"] },
          //     {  lnglat: ["116.486409", "39.921489"] },
          //     {  lnglat: ["116.658603", "39.902486"] },
          //     {  lnglat: ["116.338033", "39.728908"] },
          //     {  lnglat: ["116.235906", "40.218085"] },
          //     {  lnglat: ["116.366794", "39.915309"] },
          //     {  lnglat: ["116.418757", "39.917544"] },
          //     {  lnglat: ["116.139157", "39.735535"] },
          //     {  lnglat: ["116.195445", "39.914601"] },
          //     {  lnglat: ["116.310316", "39.956074"] },
          //     {  lnglat: ["116.286968", "39.863642"] },
          // ];
          // 绘制聚合点
          this.renderClusterMarker(params.type, points)
          this.map.addListener(this.cluster, "click", function(e) {

          })
        }
      })
    },
    differMinuteTime(startDate, endDate) { // 一分钟等于60000毫秒
      let number = Math.floor((new Date(endDate).getTime() - new Date(startDate).getTime()) / 60000);
      return number;
    },
    search() {
      if (this.map) {
        this.map.clearMap()
      }
      if (this.marker){
        this.stopAnimation()
      }
      this.cluster && this.cluster.setMap(null);
      this.getData(this.type)
      // this.getData(1)
      // 绘制销售区域
      for (let i = 0; i < this.deviceInfo.deviceAreas.length; i++) {
        let item = this.deviceInfo.deviceAreas[i]
        var level = item.county ? 'district' : (item.city ? 'city' : (item.province ? 'province' : 'city'))
        var code = item.county ? item.county : (item.city ? item.city : (item.province ? item.province : item.address))
        this.drawBounds(code, level)
      }
    }
  }
})
</script>
<style scoped lang="scss">
.gpsMapContainer {
  border: 1px solid #eee;
  min-width: 800px;
  min-height: 500px;
  margin-top: 20px;
  width: 100%;
  height: 80vh
}

.searchMain > div {
  margin-right: 10px;
}

#container .amap-marker-label {
  // position: absolute;
  // z-index: 2;
  border: 1px solid #ccc !important;
  // background-color: white;
  // white-space: nowrap;
  // cursor: default;
  // padding: 3px;
  // font-size: 12px;
  // line-height: 14px;
}

.infoDiv {
  display: inline-block;
  float: left;
  width: 250px;
}

.deviceInfo {
  position: fixed;
  z-index: 999;
  top: 184px;
  left: 250px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}

.deviceInfoItem {
  display: inline-block;
  float: left;
}

.deviceInfoData {
  display: inline-block;
  float: left;
  margin-left: 20px;
  margin-right: 20px;
}

.deviceInfoText {
  display: block;
  margin-bottom: 10px;
  color: #07160E;
}
.gjBtn{
  width: 300px;
}
.info_data {
  display: block;
  font-weight: 600;
}

.input-card {
  position: fixed;
  z-index: 999;
  top: 184px;
  right: 20px;
  background: #fff;
  //border: 1px solid #f00;
  padding: 20px;
  border-radius: 10px;
}

.input-card .btn {
  margin-right: 1.2rem;
  margin-top: 0.6rem;
  width: 9rem;
}

.input-card .btn:last-child {
  margin-right: 0;
}
::v-deep .el-icon-edit{
  background: url('../../assets/images/kaishibtn.png') center no-repeat;
  width: 13px;
  height: 13px;
}
::v-deep .el-icon-edit:before{
  content: "11";
  visibility: hidden;
}
::v-deep .btnList .el-icon-eleme{
  background: url('../../assets/images/zantingbtn.png') center no-repeat;
  width: 13px;
  height: 13px;
}
::v-deep .el-icon-eleme:before{
  content: "11";
  visibility: hidden;
}
::v-deep .btnList .el-icon-s-tools{
  background: url('../../assets/images/jixubtn.png') center no-repeat;
  width: 13px;
  height: 13px;
}
::v-deep .el-icon-s-tools:before{
  content: "11";
  visibility: hidden;
}
::v-deep .btnList .el-icon-setting{
  background: url('../../assets/images/tingzhibtn.png') center no-repeat;
  width: 13px;
  height: 13px;
}
::v-deep .el-icon-setting:before{
  content: "11";
  visibility: hidden;
}
.btnList .startBtn:hover{
  background-color: #FF707A ;
  color: #fcfcfc;
}
.ztBtn{
  width: 145px;
  height: 33px;
  background-color: #51CDCB ;
  color: #fcfcfc;
  border-radius: 4px;
  margin-left: 20px;
}
.btnList .ztBtn:hover{
  background-color: #51CDCB ;
  color: #fcfcfc;
}
.startBtn{
  width: 145px;
  height: 33px;
  background-color: #FF707A ;
  color: #fcfcfc;
  border-radius: 4px;
}
.jxBtn{
  width: 145px;
  height: 33px;
  background-color: #48DA93;
  color: #fcfcfc;
  border-radius: 4px;
}
.btnList .jxBtn:hover{
  background-color: #48DA93;
  color: #fcfcfc;
}
.tzBtn{
  width: 145px;
  height: 33px;
  background-color: #B3B3B3;
  color: #fcfcfc;
  border-radius: 4px;
  margin-left: 20px;
}
.btnList .tzBtn:hover{
  background-color: #B3B3B3;
  color: #fcfcfc;
}
.btnList{
  padding-top: 10px;
}
</style>
